import { useEffect, useState } from "react";
import CreateMarker from "../map/CreateMarker";
import { getServerMgr } from "../managers/ServerMgr";
import GenericButton from "../common/GenericButton";
import { useMapitoStore } from "../zustand/MapitoStore";
import { getUtils } from "../managers/Utils";

export default function EditAppoModal (props) {

    //STORE
    const closeModal = useMapitoStore((store) => store.closeModal)
    const appointmentTypes = useMapitoStore((store) => store.appointmentTypes)
    const updateData = useMapitoStore((store) => store.updateData)
    
    //STATE    
    const [appoType, setAppoType] = useState(0)
    const [appoDate, setAppoDate] = useState("")
    const [appoTime, setAppoTime] = useState("")
    const [appoCompleted, setAppoCompleted] = useState(false)
    const [appoCompleteDate, setAppoCompleteDate] = useState("")
    const [appoNotes, setAppoNotes] = useState("")

    const [showError, setShowError] = useState(false)

    useEffect(() => {        
        setAppoType(Number(props.appoInfo.type_id))
        setAppoDate(props.appoInfo?.date)
        setAppoTime(props.appoInfo?.time)
        setAppoCompleted(props.appoInfo?.completed)
        setAppoCompleteDate(props.appoInfo?.completion_date)
        setAppoNotes(props.appoInfo?.appointment_notes)
    }, [])

    const editMarker = () => {
        getServerMgr().editAppo({appoId: props.appoInfo.appointment_id, type: getSelectedType().id, date: appoDate, time: appoTime, timestamp: getUtils().getTimestamp(appoDate, appoTime), notes: appoNotes}, () => {
            updateData(() => {
                closeModal()
                if(props.closeSideSlider){
                    props.closeSideSlider()
                }
            })
        })
    }

    const changeType = (value) => {
        setAppoType(Number(value))
    }

    const getSelectedType = () => {
        let elemFound = appoType !== 0 ? appointmentTypes.find((elem) => elem.id == appoType) : appointmentTypes[0] //appoType === 0 is the default value that is not linked to any value
        return elemFound
    } 

    return (
        <div className="markerRow_modal_container_edit edit_appo">
            <div className="markerRow_modal_title">{"Modifica il marker"}</div>
            <div className="markerRow_modal_edit_appo">                                       
                <>
                    <div className="markerRow_modal_text_container">
                        <div>
                            <div className="markerRow_modal_text_input">DATA</div>
                            <input className="input_style" type="date" value={appoDate} onChange={(e) => setAppoDate(e.target.value)}></input>
                        </div>
                        <div>                        
                            <div className="markerRow_modal_text_input">ORARIO</div>
                            <input className="input_style" type="time" value={appoTime} onChange={(e) => setAppoTime(e.target.value)}></input>
                        </div>                        
                    </div>
                    <div className="markerRow_modal_text_container">
                        <div>
                            <div className="markerRow_modal_text_input">TIPO</div>                            
                            <select className="select_style" value={getSelectedType().name} onChange={(e) => changeType(appointmentTypes[e.target.selectedIndex].id)}>
                                {appointmentTypes.map((option) => (
                                <option key={option.id} value={option.name}>
                                    {option.name}
                                </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="markerRow_modal_text_input">NOTE</div>
                    <textarea className="textarea_style" value={appoNotes} onChange={(e) => setAppoNotes(e.target.value)}></textarea>
                    {/* <div style={{display: props.showError ? "block" : "none", color: "red"}}>ERRORE: inserire nome del punto e cognome dell'admin</div> */}
                </>
            </div>
            <div className="markerRow_modal_button_container">
                <GenericButton
                    onClick={closeModal}
                    redStyling
                    modalStyling
                    buttonText={"ANNULLA"}
                >
                </GenericButton>
                <GenericButton
                    onClick={() => {editMarker();}}
                    greenStyling
                    modalStyling
                    buttonText={"CONFERMA"}
                >
                </GenericButton>
            </div>
        </div>
    )
}