import "./GenericButton.css";

function GenericButton(props){
    const redStyling = props.redStyling ? `red_styling` : '';
    const greenStyling = props.greenStyling ? `green_styling` : '';
    const shortStyling = props.shortStyling ? `short_styling` : '';
    const modalStyling = props.modalStyling ? `generic_button_modal`: '';
    const classesStyle = `generic_button_style ${redStyling} ${greenStyling} ${shortStyling} ${modalStyling}`;

    return(
        <button className={classesStyle} onClick={props.onClick} style={props.style}>
            {props.buttonText}
        </button>
    );
}

export default GenericButton;