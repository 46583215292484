import EditMarkerModal from "../markerList/EditMarkerModal"
import DeleteMarkerModal from "../markerList/DeleteMarkerModal"
import { useMapitoStore } from "../zustand/MapitoStore"
import "./ModalContainer.css"
import { useMemo } from "react"
import CompleteAppoModal from "./CompleteAppoModal"
import DeleteAppoModal from "./DeleteAppoModal"
import EditAppoModal from "./EditAppoModal"

export default function ModalContainer(props) {
    const modalInfo = useMapitoStore((state) => state.showModal)

    const getModal = (modalInfo) => {
        switch(modalInfo.type) {
            case "edit": 
                return <EditMarkerModal {...modalInfo.props}></EditMarkerModal>
            case "delete":
                return <DeleteMarkerModal {...modalInfo.props}></DeleteMarkerModal>   
            case "complete":
                return <CompleteAppoModal {...modalInfo.props}></CompleteAppoModal>   
            case "appoDelete":
                return <DeleteAppoModal {...modalInfo.props}></DeleteAppoModal>
            case "appoEdit":
                return <EditAppoModal {...modalInfo.props}></EditAppoModal>
            default:
                return null                  
        }
    }

    const getMemoModal = useMemo(() => getModal(modalInfo), [modalInfo]);

    return(
        <div className="modalContainer_background" style={{display: modalInfo.type !== "none" ? "flex" : "none"}}>
            {/* <div className="modalContainer_container"> */}
                {
                    getMemoModal
                }
            {/* </div> */}
        </div>
    )
}