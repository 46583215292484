
import { ICONS } from "../assets/icons"
import GenericButton from "../common/GenericButton"
import "./UnderSlider.css"

export default function UnderSlider (props) {
    return(
        <div className="underslider_container">
            {/* <div onClick={props.onClose} className="sideSliderCloseButton" style={{backgroundImage: ICONS.closeSquare}}></div> */}
            <div className="underslider_textContainer">
                <div style={{margin: "5px 0", fontSize: "20px"}}>Creazione nuovo marker</div>
                <GenericButton
                    onClick={props.onConfirm}
                    buttonText={"AGGIUNGI"}
                    style={{height: "40px"}}
                >
                </GenericButton>
                <GenericButton
                    redStyling
                    onClick={props.onClose}
                    buttonText={"ANNULLA"}
                    style={{height: "40px"}}
                >
                </GenericButton>
                {/* <div className="textConfirm" onClick={props.onConfirm}>CREA NUOVO MARKER</div>
                <div className="textCancel" onClick={props.onClose}>ANNULLA</div> */}
            </div>
        </div>
    )
}