import { useState } from "react"
import "./MarkerRow.css"
import { MARKER_STATE } from "../enum/markerCompleted"
import { ICONS } from "../assets/icons"
import { getServerMgr } from "../managers/ServerMgr"
import { useMapitoStore } from "../zustand/MapitoStore"
import { IMGNOURL } from "../assets/imgNoUrl"
import AppointmentRow from "./AppointmentRow"

export default function MarkerRow (props) {
        
    //STORE
    const markersList = useMapitoStore((state) => state.markersList)
    const appointmentsList = useMapitoStore((state) => state.appointmentsList)
    const appointmentTypes = useMapitoStore((state) => state.appointmentTypes)
    const updateData = useMapitoStore((state) => state.updateData)
    const mapCenter = useMapitoStore((state) => state.mapCenter)
    const setMapCenter = useMapitoStore((state) => state.setMapCenter)
    const mapZoom = useMapitoStore((state) => state.mapZoom)
    const setMapZoom = useMapitoStore((state) => state.setMapZoom)
    const setModal = useMapitoStore((state) => state.setModal)

    const [open, setOpen] = useState(false)

    const calculateNotCompletedAppointments = (appointmentsList) => {
        let notCompletedCount = 0;
        appointmentsList.forEach((item) => {
            if(!item.completed) {notCompletedCount++}
        });
        return notCompletedCount;
    } 

    // const getColorByCompleted = (appointmentsList) => {
    //     let notCompletedCount = calculateNotCompletedAppointments(appointmentsList)
    //     if(notCompletedCount === 0) return ICONS.markerGreen;
    //     if(notCompletedCount === appointmentsList.length) return ICONS.markerRed;
    //     return ICONS.markerYellow;
    // }     

    const getMarkerColor = (appointmentsList) => {
        let completedFlag = false;
        let notCompletedFlag = false;
        let warningFlag = props.item.needWarning;
        for(let i = 0; i < appointmentsList.length /*|| !(completedFlag && notCompletedFlag)*/; i++){
            completedFlag ||= appointmentsList[i].completed;
            notCompletedFlag ||= !appointmentsList[i].completed;
        }
        if(completedFlag && notCompletedFlag){
            if(warningFlag) {return ICONS.markerYellow_warning}
            return ICONS.markerYellow
        }
        if(notCompletedFlag){
            if(warningFlag) {return ICONS.markerRed_warning}
            return ICONS.markerRed
        }
        if(warningFlag) {return ICONS.markerGreen_warning}
        return ICONS.markerGreen
    }
 
    return(
        <div>
            <div className="markerRow_container" style={{borderBottomLeftRadius: open ? "0" : "5px", borderBottomRightRadius: open ? "0" : "5px", marginBottom: open ? "0" : "10px"}}>
                <div className="markerRow_info" onClick={() => setOpen((prevState) => (!prevState))}>

                    <div className="markerRow_markerIcon" style={{backgroundImage: getMarkerColor(props.item.appointments)}} onClick={() => {props.showMarkerOnMap({lat: Number(props.item.lat), lng: Number(props.item.lng)})}}></div>
                    <div className="markerRow_info_address">{props.item.address}</div>
                    <div className="markerRow_info_appo">{props.item.appointments.length}</div>
                    <div className="markerRow_info_appo">{calculateNotCompletedAppointments(props.item.appointments)}</div>
                    <div className="markerRow_info_admin">{props.item.administrator.surname + " " + props.item.administrator.name}</div>
                    {/* <div style={{width: "calc(17vw - 60px)", paddingLeft: "15px"}}>{props.item.marker_notes ? props.item.marker_notes : <div className="italicText">{"Nessuna nota"}</div>}</div> */}
                </div>
                <div className="markerRow_info_buttons_container">
                    <div className={"markerRow_editButton"} onClick={() => {setModal("edit", {markerInfo: props.item})}}>
                        <img className={"markerRow_button"} src="./icons/icon_edit.png"></img>
                    </div>
                    <div className={"markerRow_deleteButton"} onClick={() => {setModal("delete", {markerInfo: props.item})}}>
                        <img className={"markerRow_button"} src="./icons/icon_delete.png"></img>
                    </div>
                </div>
            </div>
            <div className="markerRow_notesAdmin_container" style={{display: open ? "block" : "none"}}>
                <div className="markerRow_notes" style={{display: props.item.marker_notes ? "block" : "none"}}>
                    <div className="markerRow_notes_text">{"NOTE: " + props.item.marker_notes}</div>
                </div>
                <div className="markerRow_administrator" style={{display: open ? "flex" : "none"}}>
                    <div className="flexContainer">
                        <div className="markerRow_administrator_icon" style={{backgroundImage: ICONS.admin_name}}></div>
                        <div className="markerRow_administrator_text">{props.item.administrator.surname + " " + props.item.administrator.name}</div>
                    </div>
                    <div className="flexContainer">
                        <div className="markerRow_administrator_icon" style={{backgroundImage: ICONS.admin_telephone}}></div>
                        <div onClick={() => window.location = "tel:" + props.item.administrator.telephone} className="markerRow_administrator_text hyperlinkText">{props.item.administrator.telephone}</div>
                    </div>
                    <div className="flexContainer">
                        <div className="markerRow_administrator_icon" style={{backgroundImage: ICONS.admin_email}}></div>
                        <div onClick={() => window.location = "mailto:" + props.item.administrator.email} className="markerRow_administrator_text hyperlinkText">{props.item.administrator.email}</div>
                    </div>
                </div>
            </div>
            <div className="markerRow_appointmentsList" style={{display: open ? "block" : "none"}}>
                {
                props.item.appointments.length !== 0
                ?
                props.item.appointments.map((appo) => (
                    <AppointmentRow appo={appo}></AppointmentRow>
                ))
                :
                <div>Nessun intervento</div>
                }
            </div>
        </div>
    )
}

// const AppointmentRow = (props) => {

//     const [appoHover, setAppoHover] = useState(false);

//     const onClickAppoSetCompleted = (id, isCompleted) => {
//         // getServerMgr().setAppoCompleted(id, isCompleted);
//     }

//     const onClickAppoEdit = (id, isCompleted) => {
//         // getServerMgr().setAppoCompleted(id, isCompleted);
//     }

//     const onClickAppoDelete = (id, isCompleted) => {
//         // getServerMgr().setAppoCompleted(id, isCompleted);
//     }

//     return (
//         <div style={{display: "flex", padding: "8px", backgroundColor: appoHover ? "rgba(0, 0, 0, 0.3)" : null}} onMouseEnter={() => setAppoHover(true)} onMouseLeave={() => setAppoHover(false)}>
//             <div className={props.appo.completed ? "markerRow_decompleteButton" : "markerRow_completeButton"} onClick={() => {onClickAppoSetCompleted(props.item.id, props.item.completed)}}>
//                 <img className={"markerRow_button"} src={props.appo.completed ? IMGNOURL.appoDecomplete : IMGNOURL.appoComplete}></img>
//             </div>
//             <div style={{width: "calc(15vw - 60px)", minWidth: "115px", paddingLeft: "15px"}}>{(props.appo.completed ? <div className="appointmentRow_completed">{"COMPLETATO"}</div> : <div className="appointmentRow_todo">{"DA ESEGUIRE"}</div>)}</div>
//             {/* <div style={{width: "calc(12vw - 60px)", paddingLeft: "15px", minWidth: "160px"}}>{(props.appo.completed ? "COMPLETATO" : "NON COMPLETATO")}</div> */}
//             {/* <div style={{width: "7px", paddingLeft: "15px", borderRadius: "50%", backgroundColor: props.appo.completed ? "green" : "red"}}></div> */}
//             <div style={{width: "calc(10vw - 60px)", paddingLeft: "15px"}}>{props.appo.date}</div>
//             <div style={{width: "calc(10vw - 60px)", paddingLeft: "15px"}}>{props.appo.type_name}</div>
//             <div style={{width: "calc(17vw - 60px)", paddingLeft: "15px"}}>{props.appo.appointment_notes ? props.appo.appointment_notes : <div className="italicText">{"Nessuna nota"}</div>}</div>
//             <div className="markerRow_info">
//                 <div className={"markerRow_editButton"} onClick={() => {onClickAppoEdit(props.appo.id)}}>
//                     <img className={"markerRow_button"} src="./icons/icon_edit.png"></img>
//                 </div>
//                 <div className={"markerRow_deleteButton"} onClick={() => {onClickAppoDelete(props.appo.id)}}>
//                     <img className={"markerRow_button"} src="./icons/icon_delete.png"></img>
//                 </div>
//             </div>
//         </div>
//     )
// }