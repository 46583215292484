import { useState } from "react"
import MarkerRow from "./MarkerRow";
import { MARKER_STATE } from "../enum/markerCompleted";
import MarkerList from "./MarkerList";
import AppointmentsList from "./AppointmentsList";
import { getServerMgr } from "../managers/ServerMgr";
import EditMarkerModal from "./EditMarkerModal";
import DeleteMarkerModal from "./DeleteMarkerModal";
import { useMapitoStore } from "../zustand/MapitoStore";
import { ICONS } from "../assets/icons";
import SideSlider from "../sideSlider/SideSlider";
import Filters from "../filters/Filters";
import AppoFilters from "../filters/AppoFilters";

export default function TabContainer(props) {
        
    //STORE
    const markersList = useMapitoStore((state) => state.markersList)
    const appointmentsList = useMapitoStore((state) => state.appointmentsList)
    const appointmentTypes = useMapitoStore((state) => state.appointmentTypes)
    const updateData = useMapitoStore((state) => state.updateData)
    const mapCenter = useMapitoStore((state) => state.mapCenter)
    const setMapCenter = useMapitoStore((state) => state.setMapCenter)
    const mapZoom = useMapitoStore((state) => state.mapZoom)
    const setMapZoom = useMapitoStore((state) => state.setMapZoom)

    const [tabSelected, setTabSelected] = useState("markers")
    const [showEditModal, setShowEditModal] = useState(false)
    const [editMarkerInfo, setEditMarkerInfo] = useState({})
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteMarkerInfo, setDeleteMarkerInfo] = useState({})

    const [showFilterSlider, setShowFilterSlider] = useState(false)
    const [showFilterSliderMobile, setShowFilterSliderMobile] = useState(false)

    const getTabColor = () => {
        switch(tabSelected) {
            case "markers":
                return "#402d4d";
            case "appo":
                return "#483654";
            case "admin":
                return "#574763";
            default:
                return "#000000";
        }
    }

    const onClickEdit = (item) => {
        setEditMarkerInfo(item)
        setShowEditModal(true)
    }

    const onMarkerEdit = () => {
        setShowEditModal(false)
    } 

    const onClickDelete = (item) => {
        setDeleteMarkerInfo(item)
        setShowDeleteModal(true)
    }

    const deleteMarker = (item) => {
        getServerMgr().deleteMarker({markerId: item.id}, updateData)
        setShowDeleteModal(false);
    }

    return(
        <>
        {tabSelected === "markers" &&
            <SideSlider
                showSlider={showFilterSlider}
                showSliderMobile={showFilterSliderMobile}
                // onClose={() => {setShowCreateMarker(false); setShowSlider("none"); setMapLarge(true)}}
                onClose={() => {setShowFilterSlider(false); setShowFilterSliderMobile(false);}}
                onClickLabel={() => {setShowFilterSlider((state) => (!state)); setShowFilterSliderMobile((state) => (!state))}}
                sliderTabMarkers
            >
                {/* <div>PROVA FILTRI</div> */}
                <Filters></Filters>
            </SideSlider>
        }
        {tabSelected === "appo" &&
            <SideSlider
                showSlider={showFilterSlider}
                showSliderMobile={showFilterSliderMobile}
                // onClose={() => {setShowCreateMarker(false); setShowSlider("none"); setMapLarge(true)}}
                onClose={() => {setShowFilterSlider(false); setShowFilterSliderMobile(false);}}
                onClickLabel={() => {setShowFilterSlider((state) => (!state)); setShowFilterSliderMobile((state) => (!state))}}
                sliderTabMarkers
            >
                {/* <div>PROVA FILTRI</div> */}
                <AppoFilters></AppoFilters>
            </SideSlider>
        }
        <div className="tabContainer">
            {/* <EditMarkerModal showEditModal={showEditModal} markerInfo={editMarkerInfo} setShowEditModal={setShowEditModal} onMarkerEdit={onMarkerEdit}></EditMarkerModal> */}
            {/* <DeleteMarkerModal showDeleteModal={showDeleteModal} markerInfo={deleteMarkerInfo} setShowDeleteModal={setShowDeleteModal} deleteMarker={deleteMarker}></DeleteMarkerModal> */}
            <div>
                <div style={{display: "flex", position: "relative"}}>
                    <div className="tabContainer_outer">
                        {/* <div className="tabContainer_inner" style={{left: "20vw"}}>
                            <div className="tab_outerTab">
                                <div className="tab_tab" style={{zIndex: tabSelected === "admin" ? "5" : "0", background: "#574763"}} onClick={() => {setTabSelected("admin")}}>
                                    <div className="gradientText tab_tabText" >AMMINISTRATORI</div>
                                    <div className="tab_tabIcon" style={{backgroundImage: ICONS.tab_admin}}></div>
                                </div>
                            </div>
                        </div> */}
                        <div className="tabContainer_inner" style={{left: "10vw"}}>
                            <div className="tab_outerTab">
                                <div className="tab_tab" style={{zIndex: tabSelected === "appo" ? "5" : "0", background: "#483654"}} onClick={() => {setTabSelected("appo")}}>
                                    <div className="gradientText tab_tabText" >INTERVENTI</div>
                                    <div className="tab_tabIcon" style={{backgroundImage: ICONS.tab_appo}}></div>
                                </div>
                            </div>
                        </div>
                        <div className="tabContainer_inner" style={{left: "0"}}>
                            <div className="tab_outerTab">
                                <div className="tab_tab" style={{zIndex: tabSelected === "markers" ? "5" : "0", background: "#402d4d"}} onClick={() => {setTabSelected("markers")}}>
                                    <div className="gradientText tab_tabText" >MARKERS</div>
                                    <div className="tab_tabIcon" style={{backgroundImage: ICONS.tab_marker}}></div>
                                </div>
                            </div>   
                        </div>                 
                    </div>
                </div>
                <div className="tab_container" style={{background: getTabColor()}}>
                    <MarkerList show={tabSelected === "markers"} showMarkerOnMap={props.showMarkerOnMap} onClickDelete={onClickDelete} onClickEdit={onClickEdit}></MarkerList>
                    <AppointmentsList show={tabSelected === "appo"} showMarkerOnMap={props.showMarkerOnMap}></AppointmentsList>
                </div>
            </div>
        </div>
        </>
    )
}