import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';
import YellowMarker from './marker/YellowMarker';
import { useAutocomplete } from '@vis.gl/react-google-maps';
import "./App.css"
import "./LoginPage.css"
import { useEffect, useRef, useState } from "react"

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import BlueMarker from './marker/BlueMarker';
import GreenMarker from './marker/GreenMarker';
import TopToolbar from './toolbar/TopToolbar';
import GoogleMapContainer from './map/GoogleMapComponent';
import MarkerList from './markerList/MarkerList';
import { getServerMgr } from './managers/ServerMgr';
import { getUtils } from './managers/Utils';
import TabContainer from './markerList/TabContainer';
import { useMapitoStore } from './zustand/MapitoStore';

export default function LoginPage () {

  // const markersList = useMapitoStore((state) => state.markersList)
  // const appointmentsList = useMapitoStore((state) => state.appointmentsList)
  // const appointmentTypes = useMapitoStore((state) => state.appointmentTypes)
  const loginDone = useMapitoStore((state) => state.loginDone)
  const setLoginDone = useMapitoStore((state) => state.setLoginDone)
  const updateData = useMapitoStore((state) => state.updateData)
  const setMapCenter = useMapitoStore((state) => state.setMapCenter)
  const setMapZoom = useMapitoStore((state) => state.setMapZoom)

  const Bari = {lat: 41.11148, lng: 16.8554}

  const [singletonLoaded, setSingletonLoaded] = useState(false)
  const [currentPage, setCurrentPage] = useState("map")

  const [chiave, setChiave] = useState("")
  const [error, setError] = useState(false)

  const superSegretissima = "Sanisar_2022"

  const checkLogin = () => {
    if(superSegretissima === chiave){
        setLoginDone()
    } else {
        setError(true)
    }

  }

    return(
        <div className='loginPage' style={{display: loginDone ? "none" : "flex"}}>
            <div>
                <h2 style={{color: "white"}}>Inserisci chiave per accedere</h2>
                <input type="password" className='loginPage_input' value={chiave} onChange={(e) => {setChiave(e.target.value); setError(false)}}></input>
                <div style={{display: error ? "block" : "none", color: "red", fontSize: "larger"}}>PAROLA CHIAVE SBAGLIATA</div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div className='generic_button_style' style={{textAlign: "center"}} onClick={checkLogin}>
                        <div>LOGIN</div>
                    </div>
                </div>
            </div>
        </div>
    )
};