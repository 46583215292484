import { getServerMgr } from "../managers/ServerMgr";
import { useMapitoStore } from "../zustand/MapitoStore";

export default function DeleteAppoModal (props) {
    const closeModal = useMapitoStore((store) => store.closeModal)
    const updateData = useMapitoStore((store) => store.updateData)

    const deleteAppo = () => {        
        getServerMgr().deleteAppo({appoId: props.appoInfo.appointment_id}, () => {
            updateData(() => {
                closeModal()
                if(props.closeSideSlider){
                    props.closeSideSlider()
                }
            })
        })
    }

    return(
        <div className="markerRow_modal_container_delete">
            <div className="markerRow_modal_title">{"Eliminare l'intervento " + props.appoInfo.type_name}</div>
            <div className="markerRow_modal_button_container">
                <div className="markerRow_modal_button_cancel" onClick={closeModal}>ANNULLA</div>
                <div className="markerRow_modal_button_confirm" onClick={deleteAppo}>CONFERMA</div>
            </div>
        </div>
    )
}