import React, { useEffect, useState } from "react";

import "./SideSlider.css"
import { COLORS } from "../assets/colors";
import { ICONS } from "../assets/icons";

export default function SideSlider(props) {

    const transitionTime = 1

    const [isClosing, setIsClosing] = useState(0); //increment used to ping the children that the slider is closing


    const onClosing = () => {
        props.onClose();
        setIsClosing((prevState) => (prevState + 1))
    }
    // const [zIndexSlider, setZIndexSlider] = useState("5")

    // useEffect(() => {
    //     if(props.show === props.order) {
    //         setZIndexSlider("10")
    //     } else if(props.show !== props.order) {
    //         setTimeout(() => {
    //             setZIndexSlider("5")
    //         }, transitionTime*1000)
    //     }
    // }, [props.show])
// {width: mapLarge ? "0vw" : "40vw", height: "85vh", transition: "1s", background: "lightblue"}
    if(props.right){
        return(
            <div className={"sideSlider_container " + (props.showSlider ? "sideSlider_filter_show " : "sideSlider_filter_hide " ) + (props.showSliderMobile ? "sideSlider_filter_show_mobile " : "sideSlider_filter_hide_mobile ")} style={{background: COLORS.QUATERNARY_COLOR}}>
                <div onClick={onClosing} className="sideSliderCloseButton" style={{backgroundImage: ICONS.closeSquare}}></div>

                {/* {props.children} */}

                <div className="sideSlider_childrenContainer">
                    {React.cloneElement(props.children, { closeSideSlider: props.onClose, isClosing: isClosing, showSlider: props.showSlider, showSliderMobile: props.showSliderMobile})}
                </div>
                <div className="sideSliderSidelabel" style={{backgroundColor: COLORS.QUATERNARY_COLOR, backgroundImage: ICONS.filters}} onClick={props.onClickLabel}>

                </div>
                
            </div>
        )
    }
    else if(props.sliderTabMarkers){
        return(
            <div className={"sideSlider_container " + (props.showSlider ? "sideSlider_filter_show_MARKERS_TAB " : "sideSlider_filter_hide_MARKERS_TAB " ) + (props.showSliderMobile ? "sideSlider_filter_show_mobile " : "sideSlider_filter_hide_mobile ")} style={{background: COLORS.QUATERNARY_COLOR}}>
                <div onClick={onClosing} className="sideSliderCloseButton" style={{backgroundImage: ICONS.closeSquare}}></div>

                {/* {props.children} */}

                <div className="sideSlider_childrenContainer">
                    {React.cloneElement(props.children, { closeSideSlider: props.onClose, isClosing: isClosing, showSlider: props.showSlider, showSliderMobile: props.showSliderMobile})}
                </div>
                <div className="sideSliderSidelabel" style={{backgroundColor: COLORS.QUATERNARY_COLOR, backgroundImage: ICONS.filters}} onClick={props.onClickLabel}>

                </div>
                
            </div>
        )
    }
    else {
        return(
            <div className={"sideSlider_container " + (props.showSlider ? "sideSlider_show " : "sideSlider_hide " ) + (props.showSliderMobile ? "sideSlider_show_mobile " : "sideSlider_hide_mobile ") + (props.absolute ? "sideSlider_filter" : " ")} style={{background: COLORS.QUATERNARY_COLOR}}>
                <div onClick={onClosing} className="sideSliderCloseButton" style={{backgroundImage: ICONS.closeSquare}}></div>

                {/* {props.children} */}

                <div className="sideSlider_childrenContainer">
                    {React.cloneElement(props.children, { closeSideSlider: props.onClose, isClosing: isClosing, showSlider: props.showSlider, showSliderMobile: props.showSliderMobile})}
                </div>
            </div>
        )
    }
}