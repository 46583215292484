import { useState } from "react"
import MarkerRow from "./MarkerRow";
import { MARKER_STATE } from "../enum/markerCompleted";
import AppointmentRow from "./AppointmentRow";
import { useMapitoStore } from "../zustand/MapitoStore";

export default function AppointmentsList(props) {
        
    //STORE
    const markersList = useMapitoStore((state) => state.markersList)
    const appointmentsList = useMapitoStore((state) => state.appointmentsList)
    const appointmentTypes = useMapitoStore((state) => state.appointmentTypes)
    const updateData = useMapitoStore((state) => state.updateData)
    const mapCenter = useMapitoStore((state) => state.mapCenter)
    const setMapCenter = useMapitoStore((state) => state.setMapCenter)
    const mapZoom = useMapitoStore((state) => state.mapZoom)
    const setMapZoom = useMapitoStore((state) => state.setMapZoom)
    const valueForSorting = useMapitoStore((state) => state.sortingBy)
    const setValueForSorting = useMapitoStore((state) => state.setSortingBy)
    const appoFilters = useMapitoStore((state) => state.appoFilters)
    const filterAppo = useMapitoStore((state) => state.filterAppo)

    const [filter, setFilter] = useState("type")
    const [sortingOrder, setSortingOrder] = useState(1);

    function sortByAppoState(){
        if(valueForSorting === "appoState"){
            setSortingOrder((prevOrder) => prevOrder*-1)
        }else{
            setSortingOrder(1)
        }
        setValueForSorting("appoState")
    }
    function sortByAppoType(){
        if(valueForSorting === "appoType"){
            setSortingOrder((prevOrder) => prevOrder*-1)
        }else{
            setSortingOrder(1)
        }
        setValueForSorting("appoType")
    }

    function sortByDateTime(){
        if(valueForSorting === "dateTime"){
            setSortingOrder((prevOrder) => prevOrder*-1)
        }else{
            setSortingOrder(1)
        }
        setValueForSorting("dateTime")
    }

    function sortingList(a, b){
        switch(valueForSorting){
            case "appoState":
                if(a.completed > b.completed){
                    return 1*sortingOrder
                }
                else{
                    return -1*sortingOrder
                }
            case "appoType":
                if(a.type_id > b.type_id){
                    return 1*sortingOrder
                }
                else{
                    return -1*sortingOrder
                }
            case "dateTime":
                if(!a.timestamp && !b.timestamp){
                    return -1*sortingOrder
                }
                if(!a.timestamp && b.timestamp){
                    return -1*sortingOrder
                }
                if(a.timestamp && !b.timestamp){
                    return -1*sortingOrder
                }
                if(a.timestamp > b.timestamp){
                    return 1*sortingOrder
                }
                else{
                    return -1*sortingOrder
                }
            default:
                break;
        }
    }

    return(               
        <div className="markerList_container" style={{display: props.show ? "block" : "none"}}>
            <div className="markerRow_container" style={{borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", marginBottom: "10px"}}>
                {/* <div className="markerRow_markerIcon"></div> */}
                <div className="appointmentRow_info_container_inner" style={{paddingLeft: "4px"}}>
                    {/* <div style={{width: "40px"}}></div> */}
                    <div className="appointmentRow_info_dateTime_container">
                        <div className="markerRow_info_icon appointmentRow_info_appoDateTime_icon" style={valueForSorting === "dateTime" ? {backgroundColor: "lightblue"} : {}} onClick={sortByDateTime}>
                            {valueForSorting === "dateTime" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "dateTime" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                        <div className="markerRow_info_text" style={valueForSorting === "dateTime" ? {backgroundColor: "lightblue"} : {}} onClick={sortByDateTime}>
                            {"DATA ORA"}
                            {valueForSorting === "dateTime" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "dateTime" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                    </div>
                    <div className="appointmentRow_info_name">
                        <div className="markerRow_info_icon appointmentRow_info_appoType_icon" style={valueForSorting === "appoType" ? {backgroundColor: "lightblue"} : {}} onClick={sortByAppoType}>
                            {valueForSorting === "appoType" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "appoType" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                        <div className="markerRow_info_text" style={valueForSorting === "appoType" ? {backgroundColor: "lightblue"} : {}} onClick={sortByAppoType}>
                            {"TIPO"}
                            {valueForSorting === "appoType" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "appoType" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                    </div>
                    <div className="markerRow_completed_label">
                        <div className="markerRow_info_icon appointmentRow_info_appoState_icon" style={valueForSorting === "appoState" ? {backgroundColor: "lightblue"} : {}} onClick={sortByAppoState}>
                            {valueForSorting === "appoState" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "appoState" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                        <div className="markerRow_info_text" style={valueForSorting === "appoState" ? {backgroundColor: "lightblue"} : {}} onClick={sortByAppoState}>
                            {"STATO"}
                            {valueForSorting === "appoState" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "appoState" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                    </div>
                </div>
                <div className="appointmentRow_buttons_container" /*"markerRow_info"*/>
                    <div className={"markerRow_labels_button"}></div>
                    <div className={"markerRow_labels_button"}></div>
                </div>
            </div>
            <div style={{background: "#dbb0f2", padding: "0 15px"}}>
                {
                    // Object.keys(appointmentsList).map((key) => {
                    //     return <AppointmentRow appo={appointmentsList[key]} showMarkerOnMap={props.showMarkerOnMap} ></AppointmentRow>
                    // })
                    appointmentsList.sort(sortingList).map((appo) => {
                        if(filterAppo(appo, appoFilters)) {
                          return (
                            <AppointmentRow appo={appo} showMarkerOnMap={props.showMarkerOnMap} address></AppointmentRow>
                          )
                        } else {
                          return null;
                        }
                    })
                }
            </div>
            <div style={{height: "20px"}}></div>
        </div>
    )
}