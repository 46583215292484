import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';
import YellowMarker from './marker/YellowMarker';
import { useAutocomplete } from '@vis.gl/react-google-maps';
import "./App.css"
import { useEffect, useRef, useState } from "react"

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import BlueMarker from './marker/BlueMarker';
import GreenMarker from './marker/GreenMarker';
import TopToolbar from './toolbar/TopToolbar';
import GoogleMapContainer from './map/GoogleMapComponent';
import MarkerList from './markerList/MarkerList';
import { getServerMgr } from './managers/ServerMgr';
import { getUtils } from './managers/Utils';
import TabContainer from './markerList/TabContainer';
import { useMapitoStore } from './zustand/MapitoStore';


function initSingleton() {
  return new Promise((resolve, reject) => {
    getServerMgr( () =>
      getUtils(resolve)
    )
  })
}
export default function App () {

  // const markersList = useMapitoStore((state) => state.markersList)
  // const appointmentsList = useMapitoStore((state) => state.appointmentsList)
  // const appointmentTypes = useMapitoStore((state) => state.appointmentTypes)
  const loginDone = useMapitoStore((state) => state.loginDone)
  const updateData = useMapitoStore((state) => state.updateData)
  const setMapCenter = useMapitoStore((state) => state.setMapCenter)
  const setMapZoom = useMapitoStore((state) => state.setMapZoom)

  const Bari = {lat: 41.11148, lng: 16.8554}

  const [singletonLoaded, setSingletonLoaded] = useState(false)
  const [currentPage, setCurrentPage] = useState("map")

  useEffect(() => {
    initSingleton()
    .then(async () => {
      await updateData()
      setSingletonLoaded(true)
    })
  }, [])

  const showMarkerOnMap = (coord) => {
    setCurrentPage("map")
    setMapCenter(coord)
    setMapZoom(17)
  } 

  if(singletonLoaded){
    return(
      <div className='container_flex_responsive' style={{display: loginDone ? "flex" : "none"}}>
        <TopToolbar onClickButton={setCurrentPage} currentPage={currentPage}></TopToolbar>
        <div>
          <GoogleMapContainer show={currentPage === "map"}></GoogleMapContainer>
          <TabContainer show={currentPage === "list"} showMarkerOnMap={showMarkerOnMap}></TabContainer>      
        </div>
        </div>
    )
  } else {
    return (
      <div>
        LOADING
      </div>
    )
  }
};