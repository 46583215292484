import { useEffect, useState } from "react"
import MarkerRow from "./MarkerRow";
import { MARKER_STATE } from "../enum/markerCompleted";
import { getServerMgr } from "../managers/ServerMgr";
import { useMapitoStore } from "../zustand/MapitoStore";
import SideSlider from '../sideSlider/SideSlider';
import Filters from '../filters/Filters';

export default function MarkerList(props) {
        
    //STORE
    const markersList = useMapitoStore((state) => state.markersList)
    const filterMarker = useMapitoStore((state) => state.filterMarker)  
    const markerFilters = useMapitoStore((state) => state.markerFilters)
    const valueForSorting = useMapitoStore((state) => state.sortingBy)
    const setValueForSorting = useMapitoStore((state) => state.setSortingBy)

    //STATE
    const [showFilterSlider, setShowFilterSlider] = useState(false)
    const [showFilterSliderMobile, setShowFilterSliderMobile] = useState(false)
    const [sortingOrder, setSortingOrder] = useState(1);

    function sortByAddress(){
        if(valueForSorting === "address"){
            setSortingOrder((prevOrder) => prevOrder*-1)
        }else{
            setSortingOrder(1)
        }
        setValueForSorting("address")
    }

    function sortByAppoTotal(){
        if(valueForSorting === "appoTotal"){
            setSortingOrder((prevOrder) => prevOrder*-1)
        }else{
            setSortingOrder(1)
        }
        setValueForSorting("appoTotal")
    }

    function sortByAppoTodo(){
        if(valueForSorting === "appoTodo"){
            setSortingOrder((prevOrder) => prevOrder*-1)
        }else{
            setSortingOrder(1)
        }
        setValueForSorting("appoTodo")
    }
    function sortByAdmin(){
        if(valueForSorting === "admin"){
            setSortingOrder((prevOrder) => prevOrder*-1)
        }else{
            setSortingOrder(1)
        }
        setValueForSorting("admin")
    }

    function sortingList(a, b){
        switch(valueForSorting){
            case "address":
                if(markersList[a].address.toUpperCase() > markersList[b].address.toUpperCase()){
                    return 1*sortingOrder
                }
                else{
                    return -1*sortingOrder
                }
            case "appoTotal":
                if(markersList[a].appointments.length > markersList[b].appointments.length){
                    return 1*sortingOrder
                }
                else{
                    return -1*sortingOrder
                }
            case "appoTodo":
                let appoTodo_a = 0;
                let appoTodo_b = 0;
                markersList[a].appointments.map((appo) => {
                    if(!appo.completed){appoTodo_a++}
                })
                markersList[b].appointments.map((appo) => {
                    if(!appo.completed){appoTodo_b++}
                })

                if(appoTodo_a > appoTodo_b){
                    return 1*sortingOrder
                }
                else{
                    return -1*sortingOrder
                }
            case "admin":
                if(markersList[a].administrator.surname.toUpperCase() > markersList[b].administrator.surname.toUpperCase()){
                    return 1*sortingOrder
                }
                else{
                    return -1*sortingOrder
                }
            default:
                break;
        }
    }

    return(               
        <div className="markerList_container" style={{display: props.show ? "block" : "none"}}>
            <div className="markerRow_container" style={{borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", marginBottom: "10px"}}>
                <div className="markerRow_info">
                    <div className="markerRow_markerIcon"></div>
                    <div className="markerRow_info_address">
                        <div className="markerRow_info_icon markerRow_info_address_icon" style={valueForSorting === "address" ? {backgroundColor: "lightblue"} : {}} onClick={sortByAddress}>
                            {valueForSorting === "address" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "address" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                        <div className="markerRow_info_text" style={valueForSorting === "address" ? {backgroundColor: "lightblue"} : {}} onClick={sortByAddress}>
                            {"INDIRIZZO"}
                            {valueForSorting === "address" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "address" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                        
                    </div>
                    <div className="markerRow_info_appo">
                        <div className="markerRow_info_icon markerRow_info_appoTotal_icon" style={valueForSorting === "appoTotal" ? {backgroundColor: "lightblue"} : {}} onClick={sortByAppoTotal}>
                            {valueForSorting === "appoTotal" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "appoTotal" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                        <div className="markerRow_info_text" style={valueForSorting === "appoTotal" ? {backgroundColor: "lightblue"} : {}} onClick={sortByAppoTotal}>
                            {"TOTALI"}
                            {valueForSorting === "appoTotal" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "appoTotal" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                        
                    </div>
                    <div className="markerRow_info_appo">
                        <div className="markerRow_info_icon markerRow_info_appoTodo_icon" style={valueForSorting === "appoTodo" ? {backgroundColor: "lightblue"} : {}} onClick={sortByAppoTodo}>
                            {valueForSorting === "appoTodo" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "appoTodo" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                        <div className="markerRow_info_text" style={valueForSorting === "appoTodo" ? {backgroundColor: "lightblue"} : {}} onClick={sortByAppoTodo}>
                            {"INCOMPLETI"}
                            {valueForSorting === "appoTodo" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "appoTodo" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                        
                    </div>
                    <div className="markerRow_info_admin">
                        <div className="markerRow_info_icon markerRow_info_admin_icon" style={valueForSorting === "admin" ? {backgroundColor: "lightblue"} : {}} onClick={sortByAdmin}>
                            {valueForSorting === "admin" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "admin" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                        <div className="markerRow_info_text" style={valueForSorting === "admin" ? {backgroundColor: "lightblue"} : {}} onClick={sortByAdmin}>
                            {"AMMINISTRATORE"}
                            {valueForSorting === "admin" && sortingOrder === 1 && <div className="arrow down"></div>}
                            {valueForSorting === "admin" && sortingOrder === -1 && <div className="arrow up"></div>}
                        </div>
                        
                    </div>
                    {/* <div style={{width: "calc(20vw - 60px)", paddingLeft: "15px"}}>{"NOTE"}</div> */}
                </div>
                <div className="markerRow_info_buttonsLabel_container">
                    <div className={"markerRow_labels_button"}></div>
                    <div className={"markerRow_labels_button"}></div>
                </div>
            </div>
            <div>
                {
                    Object.keys(markersList).sort(sortingList).map((key) => {
                        let item = markersList[key]
                        if(filterMarker(item, markerFilters)) {
                          return (
                            <MarkerRow item={markersList[key]} showMarkerOnMap={props.showMarkerOnMap} onClickDelete={props.onClickDelete} onClickEdit={props.onClickEdit}></MarkerRow>
                          )
                        } else {
                          return null;
                        }
                    })
                }
            </div>
            <div style={{height: "20px"}}></div>
        </div>
    )
}