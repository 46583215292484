import { PAGES } from "../enum/pagesEnum"
import { ICONS } from "../assets/icons"
import "./TopToolbar.css"
import { useState } from "react"

export default function TopToolbar (props) {

    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false)

    const onClickToolbarButton = (e) => {
        props.onClickButton(e);
        setShowHamburgerMenu(false);
    }

    return (
        <div>
            <div className="topToolbar">
                <div className="topToolbar-mobile-hamburger" style={{backgroundImage: ICONS.hamburger}} onClick={() => setShowHamburgerMenu((prevState) => (!prevState))}></div>   
                <LogoContainer></LogoContainer>
                <div className={showHamburgerMenu ? "topToolbar-buttons-container" : "topToolbar-buttons-container-hide"}>
                    <TopToolbarButton icon={ICONS.map_grad} page={PAGES.map} selected={props.currentPage === PAGES.map} onClick={onClickToolbarButton} label={"MAPPA"}></TopToolbarButton>
                    <TopToolbarButton icon={ICONS.markerList_grad} page={PAGES.list} selected={props.currentPage === PAGES.list} onClick={onClickToolbarButton} label={"INTERVENTI"}></TopToolbarButton>
                    <TopToolbarButton icon={ICONS.settings_grad} page={PAGES.settings} selected={props.currentPage === PAGES.settings} onClick={onClickToolbarButton} label={"IMPOSTAZIONI"}></TopToolbarButton>
                </div>
            </div>
        </div>
    )
}

function TopToolbarButton(props) {
    return (
        <div style={{display: "flex", paddingLeft: "10px", marginBottom: "20px", backgroundColor: props.selected ? "rgba(52, 52, 52, 0.8)" : null}} onClick={() => {props.onClick(props.page)}}> {/* flex here or the icon is not visible */}
            <div style={{width: "50px", aspectRatio: "1/1", marginRight: "5px"}}>
                <div style={{margin: "5px", /*border: "solid 2px white",*/ height: "-webkit-fill-available", backgroundImage: props.icon, backgroundRepeat: "no-repeat", backgroundSize: "contain"}}></div>
            </div>
            <div className="gradientText">{props.label}</div>
        </div>
    )
}

function LogoContainer(props) {
    return(
        // <div className="logoBanner"> style={{backgroundImage: ICONS.logoBanner, backgroundSize: "contain"}}>
        <div className="logoBanner">
            <img src={"./logo/logo_banner_noBackground.png"}></img>
        </div>
    )
}