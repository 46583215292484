import { useState } from "react";
import { getServerMgr } from "../managers/ServerMgr";
import { useMapitoStore } from "../zustand/MapitoStore";

export default function CompleteAppoModal (props) {
    const closeModal = useMapitoStore((store) => store.closeModal)
    const updateData = useMapitoStore((store) => store.updateData)

    const [completeDate, setCompleteDate] = useState("")

    const onClickAppoSetCompleted = (id, toComplete) => {
        getServerMgr().setCompletedAppo({appoId: id, completeDate: completeDate, toComplete: toComplete == "1" ? 0 : 1}, () => {
            updateData(() => {
                closeModal()
                if(props.closeSideSlider){
                    props.closeSideSlider()
                }
            })}
        )
    }

    if(props.appoInfo.completed == "0") {
        return(
            <div className="markerRow_modal_container_delete">
                <div className="markerRow_modal_title">{"Completare l'intervento " + props.appoInfo.type_name}</div>
                <div className="flexContainer_centering">
                    <div className="label_style label_style_BORDER_WHITE">Data esecuzione: </div>
                    <input className="input_style short_width_input" type="date" value={completeDate} onChange={(e) => setCompleteDate(e.target.value)}></input>
                </div>
                <div className="markerRow_modal_button_container">
                    <div className={"markerRow_modal_button_cancel"} onClick={closeModal}>ANNULLA</div>
                    <div className={"markerRow_modal_button_confirm " + (completeDate ? "" : "disabled")} onClick={completeDate ? () => {onClickAppoSetCompleted(props.appoInfo.appointment_id, props.appoInfo.completed)} : () => {}}>CONFERMA</div>
                </div>
            </div>
        )
    } else {
        return(
            <div className="markerRow_modal_container_delete">
                <div className="markerRow_modal_title">{"Annullare il completamento dell'intervento " + props.appoInfo.type_name}</div>
                <div className="markerRow_modal_button_container">
                    <div className={"markerRow_modal_button_cancel"} onClick={closeModal}>ANNULLA</div>
                    <div className={"markerRow_modal_button_confirm"} onClick={() => {onClickAppoSetCompleted(props.appoInfo.appointment_id, props.appoInfo.completed)}}>CONFERMA</div>
                </div>
            </div>
        )
    }
}