import { useEffect, useState } from "react";
import CreateMarker from "../map/CreateMarker";
import { getServerMgr } from "../managers/ServerMgr";
import GenericButton from "../common/GenericButton";
import { useMapitoStore } from "../zustand/MapitoStore";
import { getUtils } from "../managers/Utils";

export default function EditMarkerModal (props) {

    const closeModal = useMapitoStore((store) => store.closeModal)
    const updateData = useMapitoStore((store) => store.updateData)

    const [markerName, setMarkerName] = useState("")
    const [markerNotes, setMarkerNotes] = useState("")
    const [adminSurname, setAdminSurname] = useState("")
    const [adminName, setAdminName] = useState("")
    const [adminTel, setAdminTel] = useState("")
    const [adminEmail, setAdminEmail] = useState("")
    const [needWarning, setNeedWarning] = useState(false)

    const [showError, setShowError] = useState(false)

    useEffect(() => {
        setMarkerName(props.markerInfo.address)
        setNeedWarning(props.markerInfo.needWarning)
        setMarkerNotes(props.markerInfo.marker_notes)
        setAdminSurname(props.markerInfo?.administrator?.surname)
        setAdminName(props.markerInfo?.administrator?.name)
        setAdminTel(props.markerInfo?.administrator?.telephone)
        setAdminEmail(props.markerInfo?.administrator?.email)
    }, [])

    const editMarker = () => {
        if(markerName && adminSurname) {
            getServerMgr().editMarker({markerId: props.markerInfo.id, markerName, needWarning: needWarning ? 1 : 0, markerNotes, adminId: props.markerInfo?.administrator.admin_id, adminSurname, adminName, adminTel, adminEmail}, () => {
                updateData(() => {
                    closeModal()
                    if(props.closeSideSlider){
                        props.closeSideSlider()
                    }
                })
            })
        }
    }

    return (
        <div className="markerRow_modal_container_edit">
            <div className="markerRow_modal_title">{"Modifica il marker"}</div>
            <div className="markerRow_modal_content_container">
                {/* <div> */}
                <fieldset className="fieldset_style modal_fieldset_style modal_fieldset_INFO">
                    <legend className="legend_style">CONDOMINIO</legend>
                    <div className="markerRow_modal_text_input">INDIRIZZO</div>
                    <input className="input_style long_width_input" value={markerName} onChange={(e) => setMarkerName(e.target.value)}></input>                        
                    <div className="checkbox_warning_container">
                        <input className="checkbox_style" type="checkbox" checked={needWarning} onChange={(e) => setNeedWarning((prevState) => (!prevState))}/>
                        <div>{"Necessita avviso"}</div>
                    </div>
                    <div className="markerRow_modal_text_input">NOTE</div>
                    <textarea className="textarea_style long_textarea" value={markerNotes} onChange={(e) => setMarkerNotes(e.target.value)}></textarea>
                </fieldset>
                {/* <hr style={{borderColor: "orange", width: "100%"}}></hr> */}
                <fieldset className="fieldset_style modal_fieldset_style modal_fieldset_ADMIN">
                    <legend className="legend_style">AMMINISTRATORE</legend>
                    <div className="markerRow_modal_admin_info_container">
                        <div className="markerRow_modal_text_container">
                            <div>
                                <div className="markerRow_modal_text_input">COGNOME</div>
                                <input className="input_style" value={adminSurname} onChange={(e) => setAdminSurname(e.target.value)}></input>
                            </div>
                            <div>                        
                                <div className="markerRow_modal_text_input">NOME</div>
                                <input className="input_style" value={adminName} onChange={(e) => setAdminName(e.target.value)}></input>
                            </div>                        
                        </div>
                        <div className="markerRow_modal_text_container">
                            <div>
                                <div className="markerRow_modal_text_input">TELEFONO</div>
                                <input className="input_style" value={adminTel} type={"tel"} pattern="^[0-9\b]+$" onChange={(e) => {if(getUtils().checkIfNumber(e.target.value)) setAdminTel(e.target.value)}}></input>
                            </div>
                            <div>
                                <div className="markerRow_modal_text_input">EMAIL</div>
                                <input className="input_style" value={adminEmail} type={"email"} onChange={(e) => setAdminEmail(e.target.value)}></input>
                            </div>
                        </div>
                    </div>
                    {/* <div>NOTE</div>
                    <textarea className="textarea_style" value={adminNotes} onChange={(e) => setAdminNotes(e.target.value)}></textarea> */}
                    <div style={{display: showError ? "block" : "none", color: "red"}}>ERRORE: inserire nome del punto e cognome dell'admin</div>
                </fieldset>
                
            </div>
            <div className="markerRow_modal_button_container">
                <GenericButton
                    onClick={closeModal}
                    redStyling
                    modalStyling
                    buttonText={"ANNULLA"}
                >
                </GenericButton>
                <GenericButton
                    onClick={() => {editMarker();}}
                    greenStyling
                    modalStyling
                    buttonText={"CONFERMA"}
                >
                </GenericButton>
                    {/* <div className="markerRow_modal_button_cancel" onClick={() => {props.setShowEditModal(false)}}>ANNULLA</div>
                    <div className="markerRow_modal_button_confirm" onClick={() => {editMarker();}}>CONFERMA</div> */}
            </div>
        </div>
    )
}

function AppointmentEditInfo (props) {
    return (
        <>
            <div className="markerRow_modal_text_container">
                <div>
                    <div className="markerRow_modal_text_input">DATA</div>
                    <input className="input_style" type="date" value={props.info.date} onChange={(e) => props.setAdminSurname(e.target.value)}></input>
                </div>
                <div>                        
                    <div className="markerRow_modal_text_input">ORARIO</div>
                    <input className="input_style" type="time" value={props.info.time} onChange={(e) => props.setAdminName(e.target.value)}></input>
                </div>                        
            </div>
            <div className="markerRow_modal_text_container">
                <div>
                    <div className="markerRow_modal_text_input">TIPO</div>
                    <input className="input_style" value={props.info.type} onChange={(e) => props.setAdminTel(e.target.value)}></input>
                </div>
                {/* <div>
                    <div className="markerRow_modal_text_input">EMAIL</div>
                    <input className="markerRow_modal_text_input" value={adminEmail} type={"email"} onChange={(e) => setAdminEmail(e.target.value)}></input>
                </div> */}
            </div>
            <div>NOTE</div>
            <textarea className="textarea_style" value={props.info.notes} onChange={(e) => props.setAdminNotes(e.target.value)}></textarea>
            <hr style={{borderColor: "orange", width: "100%"}}></hr>
            <div style={{display: props.showError ? "block" : "none", color: "red"}}>ERRORE: inserire nome del punto e cognome dell'admin</div>
            {/* <p>--------------</p> */}
        </>
    )
}