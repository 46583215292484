import { useRef } from "react";
import GenericButton from "../common/GenericButton";
import { useMapitoStore } from "../zustand/MapitoStore";

function Filters(){
    const appointmentsTypes = useMapitoStore((state) => state.appointmentTypes)
    const numAppo = useMapitoStore((state) => state.numAppo)
    const workingState = useMapitoStore((state) => state.workingState)
    const needWarningList = useMapitoStore((state) => state.needWarningList)
    const markerFilters = useMapitoStore((state) => state.markerFilters)
    const setMarkerFiltersAddress = useMapitoStore((state) => state.setMarkerFiltersAddress)
    const setMarkerFiltersAdministrator = useMapitoStore((state) => state.setMarkerFiltersAdministrator)
    const setMarkerFiltersNumAppo = useMapitoStore((state) => state.setMarkerFiltersNumAppo)
    const setMarkerFiltersNeedWarning = useMapitoStore((state) => state.setMarkerFiltersNeedWarning)
    const setMarkerFiltersType = useMapitoStore((state) => state.setMarkerFiltersType)
    const setMarkerFiltersWorkingState = useMapitoStore((state) => state.setMarkerFiltersWorkingState)
    const setMarkerFiltersDateFrom = useMapitoStore((state) => state.setMarkerFiltersDateFrom)
    const setMarkerFiltersDateTo = useMapitoStore((state) => state.setMarkerFiltersDateTo)
    const resetFilters = useMapitoStore((state) => state.resetFilters)

    // const numAppo = [{id: 0, value: "0"}, {id: 1, value: "1"}, {id: 2, value: "2"}, {id: 3, value: "3"}, {id: 4, value: "4"}, {id: 5, value: "5+"}]
    // const workingState = [{id: 0, value: "Completato"}, {id: 1, value: "In corso"}, {id: 2, value: "Da iniziare"}]
    // const needWarningList = [{id: 0, value: "Con avviso"}, {id: 1, value: "Senza avviso"}]
    
    const dateFromInputRef = useRef(null);
    const dateToInputRef = useRef(null);

    return(
        <>
            {/* <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start"}}> */}

                {/*ADDRESS*/}
                <fieldset className="fieldset_style short_fieldset_style">
                    <input className="input_style short_width_input" placeholder="Indirizzo..." onChange={(e) => setMarkerFiltersAddress(e.target.value)} value={markerFilters.address}></input>
                    <legend className="legend_style">INDIRIZZO</legend>
                </fieldset>

                {/*ADMIN*/}
                <fieldset className="fieldset_style short_fieldset_style">
                    <input className="input_style short_width_input" placeholder="Nome amministratore..." onChange={(e) => setMarkerFiltersAdministrator(e.target.value)} value={markerFilters.administrator}></input>
                    <legend className="legend_style">AMMINISTRATORE</legend>
                </fieldset>

                {/*NUM APPO*/}
                <fieldset className="fieldset_style short_fieldset_style">
                    <legend className="legend_style">N. INTERVENTI</legend>
                    {numAppo.map((item) => (
                        <div key={item.id} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <input className="checkbox_style" onChange={() => {setMarkerFiltersNumAppo(item.id, markerFilters.numAppo.includes(item.id))}} checked={markerFilters.numAppo.includes(item.id)} id="Cockroaches" type="checkbox" value="Cockroaches"></input>
                            <label className="content_info" htmlFor="Cockroaches">{item.value}</label>
                        </div>
                    ))}
                </fieldset>
                
                {/*NEED WARNING*/}
                <fieldset className="fieldset_style short_fieldset_style">
                    <legend className="legend_style">RICHIEDE AVVISO</legend>
                    {
                        needWarningList.map((item) => (
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <input className="checkbox_style" onChange={() => setMarkerFiltersNeedWarning(item.id, markerFilters.needWarning.includes(item.id))} checked={markerFilters.needWarning.includes(item.id)} id="Warning" type="checkbox" value="Warning"></input>
                                <label className="content_info" htmlFor="Warning">{item.value}</label>
                            </div>
                        ))
                    }
                </fieldset>
                
                {/*TYPE*/}
                <fieldset className="fieldset_style short_fieldset_style">
                    <legend className="legend_style">TIPO INTERVENTO</legend>
                    {appointmentsTypes.map((item) => (
                        <div key={item.id} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <input className="checkbox_style" onChange={() => {setMarkerFiltersType(Number(item.id), markerFilters.types.includes(Number(item.id)))}} checked={markerFilters.types.includes(Number(item.id))} id="Cockroaches" type="checkbox" value="Cockroaches"></input>
                            <label className="content_info" htmlFor="Cockroaches">{item.name}</label>
                        </div>
                    ))}
                </fieldset>
                
                {/*WORKING STATE*/}
                <fieldset className="fieldset_style short_fieldset_style">
                    <legend className="legend_style">STATO DEL LAVORO</legend>
                    {workingState.map((item) => (
                        <div key={item.id} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <input className="checkbox_style" onChange={() => {setMarkerFiltersWorkingState(item.id, markerFilters.workingState.includes(item.id))}} checked={markerFilters.workingState.includes(item.id)} id="Cockroaches" type="checkbox" value="Cockroaches"></input>
                            <label className="content_info" htmlFor="Cockroaches">{item.value}</label>
                        </div>
                    ))}
                </fieldset>
                
                {/*DATE*/}
                <fieldset className="fieldset_style short_fieldset_style">
                    <legend className="legend_style">DATE</legend>
                    <label className="label_style">dal</label>
                    <input className="input_style short_width_input" type="date" placeholder="Data inizio" value={markerFilters.dateFrom} max={markerFilters.dateTo} onChange={(e) => {setMarkerFiltersDateFrom(e.target.value)}} ref={dateFromInputRef}></input>
                    <label className="label_style">al</label>
                    <input className="input_style short_width_input" type="date" placeholder="Data fine" value={markerFilters.dateTo} min={markerFilters.dateFrom} onChange={(e) => {setMarkerFiltersDateTo(e.target.value)}} ref={dateToInputRef}></input>
                </fieldset>
                
                {/*RESET*/}
                <GenericButton
                    buttonText={"Reset Filtri"}
                    onClick={resetFilters}
                >
                </GenericButton>
            {/* </div> */}

        </>
    );
}

export default Filters;