import { getServerMgr } from "../managers/ServerMgr";
import { useMapitoStore } from "../zustand/MapitoStore";

export default function DeleteMarkerModal (props) {
    const closeModal = useMapitoStore((store) => store.closeModal)
    const updateData = useMapitoStore((store) => store.updateData)

    const deleteMarker = () => {        
        getServerMgr().deleteMarker({markerId: props.markerInfo.id}, () => {
            updateData(() => {
                closeModal()
                if(props.closeSideSlider){
                    props.closeSideSlider()
                }
            })
        })
    }

    return(
        <div className="markerRow_modal_container_delete">
            <div className="markerRow_modal_title">{"Eliminare il marker " + props.markerInfo.address}</div>
            <div className="markerRow_modal_button_container">
                <div className="markerRow_modal_button_cancel" onClick={closeModal}>ANNULLA</div>
                <div className="markerRow_modal_button_confirm" onClick={deleteMarker}>CONFERMA</div>
            </div>
        </div>
    )
}