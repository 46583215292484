import { useRef } from "react";
import GenericButton from "../common/GenericButton";
import { useMapitoStore } from "../zustand/MapitoStore";

function AppoFilters(){
    const appointmentsTypes = useMapitoStore((state) => state.appointmentTypes)
    const workingStateAppo = useMapitoStore((state) => state.workingStateAppo)
    const appoFilters = useMapitoStore((state) => state.appoFilters)
    const setAppoFiltersAddress = useMapitoStore((state) => state.setAppoFiltersAddress)
    const setAppoFiltersType = useMapitoStore((state) => state.setAppoFiltersType)
    const setAppoFiltersWorkingState = useMapitoStore((state) => state.setAppoFiltersWorkingState)
    const setAppoFiltersDateFrom = useMapitoStore((state) => state.setAppoFiltersDateFrom)
    const setAppoFiltersDateTo = useMapitoStore((state) => state.setAppoFiltersDateTo)
    const setAppoFiltersCompletedFrom = useMapitoStore((state) => state.setAppoFiltersCompletedFrom)
    const setAppoFiltersCompletedTo = useMapitoStore((state) => state.setAppoFiltersCompletedTo)
    const resetAppoFilters = useMapitoStore((state) => state.resetAppoFilters)

    // const numAppo = [{id: 0, value: "0"}, {id: 1, value: "1"}, {id: 2, value: "2"}, {id: 3, value: "3"}, {id: 4, value: "4"}, {id: 5, value: "5+"}]
    // const workingState = [{id: 0, value: "Completato"}, {id: 1, value: "In corso"}, {id: 2, value: "Da iniziare"}]
    // const needWarningList = [{id: 0, value: "Con avviso"}, {id: 1, value: "Senza avviso"}]
    
    const dateFromInputRef = useRef(null);
    const dateToInputRef = useRef(null);

    return(
        <>
            {/* <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start"}}> */}

                {/*ADDRESS*/}
                <fieldset className="fieldset_style short_fieldset_style">
                    <input className="input_style short_width_input" placeholder="Indirizzo..." onChange={(e) => setAppoFiltersAddress(e.target.value)} value={appoFilters.address}></input>
                    <legend className="legend_style">INDIRIZZO</legend>
                </fieldset>
                
                {/*TYPE*/}
                <fieldset className="fieldset_style short_fieldset_style">
                    <legend className="legend_style">TIPO INTERVENTO</legend>
                    {appointmentsTypes.map((item) => (
                        <div key={item.id} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <input className="checkbox_style" onChange={() => {setAppoFiltersType(Number(item.id), appoFilters.types.includes(Number(item.id)))}} checked={appoFilters.types.includes(Number(item.id))} id="Cockroaches" type="checkbox" value="Cockroaches"></input>
                            <label className="content_info" htmlFor="Cockroaches">{item.name}</label>
                        </div>
                    ))}
                </fieldset>
                
                {/*WORKING STATE*/}
                <fieldset className="fieldset_style short_fieldset_style">
                    <legend className="legend_style">STATO DEL LAVORO</legend>
                    {workingStateAppo.map((item) => (
                        <div key={item.id} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <input className="checkbox_style" onChange={() => {setAppoFiltersWorkingState(item.id, appoFilters.workingState.includes(item.id))}} checked={appoFilters.workingState.includes(item.id)} id="Cockroaches" type="checkbox" value="Cockroaches"></input>
                            <label className="content_info" htmlFor="Cockroaches">{item.value}</label>
                        </div>
                    ))}
                </fieldset>
                
                {/*DATE*/}
                <fieldset className="fieldset_style short_fieldset_style">
                    <legend className="legend_style">DATE</legend>
                    <label className="label_style">dal</label>
                    <input className="input_style short_width_input" type="date" placeholder="Data inizio" value={appoFilters.dateFrom} max={appoFilters.dateTo} onChange={(e) => {setAppoFiltersDateFrom(e.target.value)}} ref={dateFromInputRef}></input>
                    <label className="label_style">al</label>
                    <input className="input_style short_width_input" type="date" placeholder="Data fine" value={appoFilters.dateTo} min={appoFilters.dateFrom} onChange={(e) => {setAppoFiltersDateTo(e.target.value)}} ref={dateToInputRef}></input>
                </fieldset>
                
                {/*COMPLETION DATE*/}
                <fieldset className="fieldset_style short_fieldset_style">
                    <legend className="legend_style">COMPLETAMENTO</legend>
                    <label className="label_style">dal</label>
                    <input className="input_style short_width_input" type="date" placeholder="Data inizio" value={appoFilters.completedFrom} max={appoFilters.completedTo} onChange={(e) => {setAppoFiltersCompletedFrom(e.target.value)}} ref={dateFromInputRef}></input>
                    <label className="label_style">al</label>
                    <input className="input_style short_width_input" type="date" placeholder="Data fine" value={appoFilters.completedTo} min={appoFilters.completedFrom} onChange={(e) => {setAppoFiltersCompletedTo(e.target.value)}} ref={dateToInputRef}></input>
                </fieldset>
                
                {/*RESET*/}
                <GenericButton
                    buttonText={"Reset Filtri"}
                    onClick={resetAppoFilters}
                >
                </GenericButton>
            {/* </div> */}

        </>
    );
}

export default AppoFilters;