import {APIProvider, AdvancedMarker, Map, Marker, Pin} from '@vis.gl/react-google-maps';
import { useAutocomplete } from '@vis.gl/react-google-maps';
import "./map.css"
import { useEffect, useRef, useState } from "react"

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import SideSlider from '../sideSlider/SideSlider';
import GenericCustomMarker from '../marker/GenericCustomMarker';
import MarkerInfo from './MarkerInfo';
import CreateMarker from './CreateMarker';
import { IMGNOURL } from '../assets/imgNoUrl';
import UnderSlider from '../underSlider/UnderSlider';
import Filters from '../filters/Filters';
import { useMapitoStore } from '../zustand/MapitoStore';
import { ICONS } from '../assets/icons';

export default function GoogleMapContainer (props) {

  //STORE
  const markersList = useMapitoStore((state) => state.markersList)
  const appointmentsList = useMapitoStore((state) => state.appointmentsList)
  const appointmentTypes = useMapitoStore((state) => state.appointmentTypes)
  const updateData = useMapitoStore((state) => state.updateData)
  const mapCenter = useMapitoStore((state) => state.mapCenter)
  const setMapCenter = useMapitoStore((state) => state.setMapCenter)
  const mapZoom = useMapitoStore((state) => state.mapZoom)
  const setMapZoom = useMapitoStore((state) => state.setMapZoom)
  const filterMarker = useMapitoStore((state) => state.filterMarker)

  const markerFilters = useMapitoStore((state) => state.markerFilters)
  
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState('');

  // const [mapLarge, setMapLarge] = useState(true);
  const [markerInfo, setMarkerInfo] = useState({});
  const [newCreatedMarkerId, setNewCreatedMarkerId] = useState(0);
  // const [showMarkerInfo, setShowMarkerInfo] = useState(false)
  const [mapPointSelected, setMapPointSelected] = useState({})
  // const [showCreateMarker, setShowCreateMarker] = useState(false)
  const [showSlider, setShowSlider] = useState("none")
  const [showSliderMobile, setShowSliderMobile] = useState("none")
  const [showUnderSlider, setShowUnderSlider] = useState(false)
  const [showFilterSlider, setShowFilterSlider] = useState(false)
  const [showFilterSliderMobile, setShowFilterSliderMobile] = useState(false)

  const [tempNewMarkerPosition, setTempNewMarkerPosition] = useState(null)
  const [tempNewMarkerAddress, setTempNewMarkerAddress] = useState("")

  const [showRightSlider, setShowRightSlider] = useState(true)
  const [coordsToShow, setCoordsToShow] = useState([0,0])

  useEffect(() => {
    let currentMarkerId = markerInfo.id
    if(markersList[currentMarkerId]) {
      setMarkerInfo(markersList[currentMarkerId])
    } else {
      setMarkerInfo({})
    }
  }, [markersList])

  useEffect(() => {     
    if(newCreatedMarkerId != 0) {
      setMarkerInfo(markersList[newCreatedMarkerId])
      // setShowCreateMarker(false)
      // setShowMarkerInfo(true)
      setTempNewMarkerPosition(null)
      setTempNewMarkerAddress(""); 
      setShowSlider("info")
      setShowSliderMobile("info")
    }
  }, [newCreatedMarkerId])

  useEffect(() => {
    let currentMarkerId = markerInfo.id
    if(markersList[currentMarkerId]) {
      setMarkerInfo(markersList[currentMarkerId])
    } else {
      setMarkerInfo({})
    }
  }, [markersList])

  const onPlaceChanged = place => {
    if (place) {
      setInputValue(place.formatted_address || place.name);
    }

    // Keep focus on input element
    inputRef.current && inputRef.current.focus();
  };

  useAutocomplete({
    inputField: inputRef && inputRef.current,
    onPlaceChanged
  });

  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  const OnClickSlider = (orderId) => {
    setShowRightSlider(orderId === showRightSlider ? -1 : orderId)
  }
  const onClickMap = (e) => {
    let coord = e.detail.latLng
    console.log(e.detail.latLng)
    setMapPointSelected(coord)
    setMapCenter(e.detail.latLng)
    setTempNewMarkerPosition(coord)
    setTempNewMarkerAddress("")  
    // setMapLarge(false)
    // setShowCreateMarker(true)
    setShowSlider("create")
    setShowUnderSlider(true)
  }

  // const onClickMarker = (e) => {
  //   let coord = {lat: e.latLng.lat(), lng: e.latLng.lng()}
  //   console.log(coord)
  //   props.setMapCenter(coord)
  //   setMapLarge(false)
  // }

  const onClickMarker = (e, markerInfo) => {
    let coord = {lat: e.latLng.lat(), lng: e.latLng.lng()}
    setTempNewMarkerPosition(null)
    setTempNewMarkerAddress("")  
    setMarkerInfo(markerInfo)
    setMapCenter(coord)
    // setMapLarge(false)
    // setShowMarkerInfo(true)
    setShowSlider("info")
    setShowSliderMobile("info")
  }

  const onCreateMarker = (latLngObj, address) => {
    setTempNewMarkerPosition(latLngObj)
    setTempNewMarkerAddress(address) 
    setShowSlider("create")
    setShowSliderMobile("none")
    setShowUnderSlider(true) 
  }

  const onClickSearch = () => {
    setShowSlider("search")
    setShowSliderMobile("search")
  }

  const onMarkerCreation = (id) => {
    updateData(() => {
      setNewCreatedMarkerId(id)
    });
  }

  const onCloseUnderSlider = () => {
    setShowUnderSlider(false);
    setTempNewMarkerPosition(null)
    setTempNewMarkerAddress("")  
  }

  const onConfirmCreateNewMarker = () => {
    setShowUnderSlider(false);
    setShowSliderMobile("create")
  }

  const onDragOnMap = () => {
    setTempNewMarkerPosition(null); 
    setShowSlider("none"); 
    setTempNewMarkerAddress(""); 
    setShowUnderSlider(false);
    setShowSliderMobile("none")
  }

  return(
      <div className='map_container' style={{display: props.show ? "block" : "none"}}>
        <APIProvider apiKey={"AIzaSyDqxxg8HpKpqNQZtWlu8dquZBw4pWcq6m0"} libraries={['places']}>
          <div className='autocompleteContainer'>
            <PlacesAutocomplete setSelected={setSelected} setMapCenter={setMapCenter} onCreateMarker={onCreateMarker}></PlacesAutocomplete>
          </div>               
          <div className='autocompleteButtonMobile' style={{backgroundImage: ICONS.search_map}} onClick={onClickSearch}></div>     
          <div className={showUnderSlider ? "underslider_show" : "underslider_hide" }>
            <UnderSlider onClose={onCloseUnderSlider} onConfirm={onConfirmCreateNewMarker}></UnderSlider>
          </div>
          {/* <div style={{position: "absolute", height: "100px", width: "100px", background: "red", zIndex: "10000", right: "0"}} onClick={() => {setShowFilterSlider(true); setShowFilterSliderMobile(true)}}></div> */}
          <div className='container_flex_responsive' style={{position: "relative"}}>
            <div className={"map_render " + (showSlider === "none" ? "map_render_full" : "map_render_shrink")} /*, transition: "1s"*/>
              <Map
                onClick={onClickMap}
                onDragstart={onDragOnMap}
                zoom={mapZoom}
                center={mapCenter}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
                disableDoubleClickZoom={true}
                mapId={"e82593769cbb7a8d"}
                onZoomChanged={setMapZoom}
                // styles={[                 
                //   {
                //     featureType: 'poi',
                //     elementType: 'labels',
                //     stylers: [{visibility: 'off'}]
                //   },
                // ]}
              >
                {Object.keys(markersList).map((markerKey) => {
                  let item = markersList[markerKey]
                  if(filterMarker(item, markerFilters)) {
                    return (
                      <GenericCustomMarker key={item.id} position={{lat: Number(item.lat), lng: Number(item.lng)}} onClick={onClickMarker} info={item} appointments={item.appointments}></GenericCustomMarker>
                    )
                  } else {
                    return null;
                  }
                })}   
                {
                  tempNewMarkerPosition
                  ?
                  <AdvancedMarker position={tempNewMarkerPosition} >
                    <img className="markerSize" src={IMGNOURL.markerAdd}></img>
                  </AdvancedMarker>
                  :
                  null
                }
              </Map>
            </div>
            <SideSlider
              showSlider={showFilterSlider}
              showSliderMobile={showFilterSliderMobile}
              // onClose={() => {setShowCreateMarker(false); setShowSlider("none"); setMapLarge(true)}}
              onClose={() => {setShowFilterSlider(false); setShowFilterSliderMobile(false);}}
              onClickLabel={() => {setShowFilterSlider((state) => (!state)); setShowFilterSliderMobile((state) => (!state))}}
              right
            >
              {/* <div>PROVA FILTRI</div> */}
              <Filters></Filters>
            </SideSlider>
            <SideSlider
              showSlider={showSlider === "info"}
              showSliderMobile={showSliderMobile === "info"}
              //onClose={() => {setShowMarkerInfo(false); setShowSlider("none"); setMapLarge(true)}}
              onClose={() => {setShowSlider("none"); setShowSliderMobile("none")}}
            >
              <MarkerInfo
                info={markerInfo}
                // refreshMarkersList={updateData}
                // appointmentTypes={appointmentTypes}
              ></MarkerInfo>
            </SideSlider>
            <SideSlider
              showSlider={showSlider === "create"}
              showSliderMobile={showSliderMobile === "create"}
              // onClose={() => {setShowCreateMarker(false); setShowSlider("none"); setMapLarge(true)}}
              onClose={() => {setShowSlider("none"); setShowSliderMobile("none"); setTempNewMarkerPosition(null); setTempNewMarkerAddress("")}}
            >
              <CreateMarker
                info={mapPointSelected}
                newMarkerPosition={tempNewMarkerPosition}
                newMarkerAddress={tempNewMarkerAddress}  
                onMarkerCreation={onMarkerCreation}
              ></CreateMarker>
            </SideSlider>
            <SideSlider
              showSlider={showSlider === "search"}
              showSliderMobile={showSliderMobile === "search"}
              // onClose={() => {setShowCreateMarker(false); setShowSlider("none"); setMapLarge(true)}}
              onClose={() => {setShowSlider("none"); setShowSliderMobile("none");}}
            >              
              <PlacesAutocomplete setSelected={setSelected} setMapCenter={setMapCenter} onCreateMarker={onCreateMarker}></PlacesAutocomplete>
            </SideSlider>
          </div>
        </APIProvider>
      </div>
  )
};

const PlacesAutocomplete = ({ setSelected, setMapCenter, onCreateMarker }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions:{language: "it"}
  });

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
    onCreateMarker({ lat, lng }, address)
    setMapCenter({ lat, lng });
  };

  return (
    <>
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        placeholder="Search an address"
        className='autocompleteInput'
      />
      <hr className='autocompleteHr'></hr>
      {data.map(({ place_id, description }) => (
        <div key={place_id} onClick={() => {handleSelect(description)}} className='autocompleteResult'>
          {description}
        </div>
      ))}
    </>
  );
};