export const COLORS = {
    PRIMARY_COLOR: "#6F2DBD",
    SECONDARY_COLOR: "#a663cc",
    TERTIARY_COLOR: "#b298dc",
    QUATERNARY_COLOR: "#9e7fa8",
    FIFTH_COLOR: "#b9faf8",

    MARKER_GREEN: "#08A045",
    MARKER_YELLOW: "#FFF200",
    MARKER_RED: "#D30000",
}