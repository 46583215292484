import { useState } from "react"
import "./MarkerRow.css"
import { MARKER_STATE } from "../enum/markerCompleted"
import { ICONS } from "../assets/icons"
import { IMGNOURL } from "../assets/imgNoUrl";
import { getServerMgr } from "../managers/ServerMgr";
import { useMapitoStore } from "../zustand/MapitoStore";
import NotInserted from "../common/NotInserted";
import { getUtils } from "../managers/Utils";
import NoteButton from "./NoteButton";

export default function AppointmentRow (props) {

    const Utils = getUtils();

    const updateData = useMapitoStore((state) => state.updateData)
    const setModal = useMapitoStore((state) => state.setModal)
    
    const [appoHover, setAppoHover] = useState(false);    

    const onClickAppoSetCompleted = (id, toComplete) => {
        getServerMgr().setCompletedAppo({appoId: id, toComplete: toComplete == "1" ? 0 : 1},
            updateData
        )
    }

    return (
        <>
            <div className="appointmentRow_info_container" style={{backgroundColor: appoHover ? "rgba(0, 0, 0, 0.3)" : null}} onMouseEnter={() => setAppoHover(true)} onMouseLeave={() => setAppoHover(false)}>
                {/* <div className="appointmentRow_icon" style={{backgroundImage: props.appo.completed == "1" ? ICONS.dot_green : ICONS.dot_red}}></div> */}
                <div className="appointmentRow_info_container_inner">
                    <div className="appointmentRow_info_dateTime_container">
                        {(props.appo.date !== "0000-00-00" && props.appo.date) ? <div>{Utils.parseDate(props.appo.date)}</div> : <NotInserted/>}
                        {props.appo.time ? <div>{props.appo.time}</div> : <NotInserted/>}
                    </div>
                    <div className="appointmentRow_info_name">{props.appo.type_name}</div>     
                    <div className={"markerRow_completed_label " + (props.appo.completed == "1" ? "markerRow_completed_label_completed" : "markerRow_completed_label_notCompleted")}></div>               
                    <div className="appointmentRow_completionDate" style={{width: "100px", minWidth: "100px", paddingLeft: "15px", textAlign: "center"}}>{props.appo.completed == "1" ? Utils.parseDate(props.appo.completion_date) : ""}</div>
                    {/* <div style={{width: "calc(17vw - 60px)", paddingLeft: "15px"}}>{props.appo.appointment_notes ? props.appo.appointment_notes : <div className="italicText">{"Nessuna nota"}</div>}</div> */}
                </div>
                <div className="appointmentRow_buttons_container">                    
                    <div className={props.appo.completed == "1" ? "markerRow_decompleteButton" : "markerRow_completeButton"} onClick={() => {setModal("complete", {appoInfo: props.appo})}/*{onClickAppoSetCompleted(props.appo.appointment_id, props.appo.completed)}*/}>
                        <img className={"appointmentRow_button"} src={props.appo.completed == "1" ? IMGNOURL.appoDecomplete : IMGNOURL.appoComplete}></img>
                    </div>
                    {/* <NoteButton notes={props.appo.appointment_notes}></NoteButton> */}
                    <div className="markerRow_info">
                        <div className={"appointmentRow_editButton"} onClick={() => {setModal("appoEdit", {appoInfo: props.appo})}}>
                            <img className={"appointmentRow_button"} src="./icons/icon_edit.png"></img>
                        </div>
                        <div className={"appointmentRow_deleteButton"} onClick={() => {setModal("appoDelete", {appoInfo: props.appo})}}>
                            <img className={"appointmentRow_button"} src="./icons/icon_delete.png"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="appointmentRow_completionDate_mobile appointmentRow_address" style={props.appo.completed == "1" ? {} : {display: "none"}}>{"Completato il: " + Utils.parseDate(props.appo.completion_date)}</div>
            {
                props.address 
                ? 
                <AppointmentRowAddress>{props.appo.address}</AppointmentRowAddress>
                :
                null
            }
            <div className="appointmentRow_notes_container" style={{display: props.appo.appointment_notes ? "block" : "none"}}><b>NOTA: </b> {props.appo.appointment_notes}</div>
            <div className="appointmentRow_buttons_container_mobile">
                <div className="markerRow_info">
                    <div className={props.appo.completed == "1" ? "markerRow_decompleteButton" : "markerRow_completeButton"} onClick={() => {setModal("complete", {appoInfo: props.appo})}/*{onClickAppoSetCompleted(props.appo.appointment_id, props.appo.completed)}*/}>
                        <img className={"appointmentRow_button"} src={props.appo.completed == "1" ? IMGNOURL.appoDecomplete : IMGNOURL.appoComplete}></img>
                    </div>
                    {/* <NoteButton notes={props.appo.appointment_notes}></NoteButton> */}
                    <div className={"appointmentRow_editButton"} onClick={() => {setModal("appoEdit", {appoInfo: props.appo})}}>
                        <img className={"appointmentRow_button"} src="./icons/icon_edit.png"></img>
                    </div>
                    <div className={"appointmentRow_deleteButton"} onClick={() => {setModal("appoDelete", {appoInfo: props.appo})}}>
                        <img className={"appointmentRow_button"} src="./icons/icon_delete.png"></img>
                    </div>
                </div>
            </div>
            <hr/>
        </>
    )
}

const AppointmentRowAddress = (props) => {
    return (
        <div className="appointmentRow_address">
            {props.children}
        </div>
    )
}