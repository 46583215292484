import { useEffect, useState } from "react"
import { getServerMgr } from "../managers/ServerMgr"
import "./createMarker.css"
import GenericButton from "../common/GenericButton"
import { getUtils } from "../managers/Utils"

export default function CreateMarker(props) {

    const [markerName, setMarkerName] = useState("")
    const [markerNotes, setMarkerNotes] = useState("")
    const [adminSurname, setAdminSurname] = useState("")
    const [adminName, setAdminName] = useState("")
    const [adminTel, setAdminTel] = useState("")
    const [adminEmail, setAdminEmail] = useState("")
    const [adminNotes, setAdminNotes] = useState("")
    const [needWarning, setNeedWarning] = useState(false)

    const [showError, setShowError] = useState(false)

    useEffect(() => {
        setMarkerName(props.newMarkerAddress)
    }, [props.newMarkerAddress])

    useEffect(() => {
        setMarkerName("")
        setMarkerNotes("")
        setAdminSurname("")
        setAdminName("")
        setAdminTel("")
        setAdminEmail("")
        setAdminNotes("")
        setNeedWarning(false)
    }, [props.isClosing])

    useEffect(() => {
        setMarkerName(props.newMarkerAddress ? props.newMarkerAddress : "")
        setMarkerNotes("")
        setAdminSurname("")
        setAdminName("")
        setAdminTel("")
        setAdminEmail("")
        setAdminNotes("")
        setNeedWarning(false)
    }, [props.showSlider, props.showSliderMobile])    
    
    const createMarker = () => {
        if(markerName && adminSurname) {
            getServerMgr().addMarker({markerLat: props.newMarkerPosition.lat, markerLng: props.newMarkerPosition.lng, markerName, markerNotes, adminSurname, adminName, adminTel, adminEmail, adminNotes, needWarning}, props.onMarkerCreation)
        } else {
            setShowError(true)
        }
    }

    return(
        // <div style={{width: "calc(100% - 30px)", height: "100%", padding: " 15px 15px 0 15px"}}>
        <div style={{width: "100%", height: "100%"}}>
            <div>{props.info.address}</div>

            <fieldset className="fieldset_style">
                <legend className="legend_style">CONDOMINIO</legend>
                <div className="label_style">INDIRIZZO</div>
                <input className="input_style" value={markerName} onChange={(e) => setMarkerName(e.target.value)}></input>
                <div className="label_style">NOTE CONDOMINIO</div>
                <input className="input_style" value={markerNotes} onChange={(e) => setMarkerNotes(e.target.value)}></input>
                <div className="checkbox_warning_container">
                <input className="checkbox_style" type="checkbox" checked={needWarning} onChange={(e) => setNeedWarning((prevState) => (!prevState))}/>
                <div>{"Necessita avviso"}</div>
            </div>
            </fieldset>
            
            <fieldset className="fieldset_style">
                <legend className="legend_style">AMMINISTRATORE</legend>
                <div className="label_style">COGNOME</div>
                <input className="input_style" value={adminSurname} onChange={(e) => setAdminSurname(e.target.value)}></input>
                <div className="label_style">NOME</div>
                <input className="input_style" value={adminName} onChange={(e) => setAdminName(e.target.value)}></input>
                <div className="label_style">TELEFONO</div>
                <input className="input_style" value={adminTel} type={"tel"} onChange={(e) => {if(getUtils().checkIfNumber(e.target.value)) setAdminTel(e.target.value)}}></input>
                <div className="label_style">EMAIL</div>
                <input className="input_style" value={adminEmail} type={"email"} onChange={(e) => setAdminEmail(e.target.value)}></input>
                {/* <div className="label_style">NOTE ADMIN</div>
                <input className="input_style" value={adminNotes} onChange={(e) => setAdminNotes(e.target.value)}></input> */}
            </fieldset>

            {/* <p>AMMINISTRATORE</p> */}
            
            {/* <div style={{width: "fit-content", minWidth: "330px", display: "flex", justifyContent: "center"}}> */}
                <GenericButton
                    onClick={createMarker}
                    buttonText={"CREA MARKER"}
                >
                </GenericButton>
            {/* </div> */}
            
            {/* <button onClick={createMarker}>CREA MARKER</button> */}
            <div style={{display: showError ? "block" : "none", color: "red", marginTop: "10px"}}>ERRORE: inserire nome del punto e cognome dell'admin</div>
        </div>
    )
}

const AdministratorInfo = (props) => {
    return(
        <div style={{marginLeft: "10px"}}>
            <div>{"COGNOME: "+props.adminInfo?.surname}</div>
            <div>{"NOME: "+props.adminInfo?.name}</div>
            <div>{"TELEFONO: "+props.adminInfo?.telephone}</div>
            <div>{"EMAIL: "+props.adminInfo?.email}</div>
            <div>{"NOTE: "+props.adminInfo?.adm_notes}</div>
        </div>
    )
}

const AppointmentInfo = (props) => {
    return(
        <div style={{marginLeft: "10px", marginBottom: "10px"}}>
            <div>{"DATA: "+props.appointment?.date}</div>  
            <div>{"TIPO: "+props.appointment?.type_name}</div>  
            <div>{"COMPLETATO: "+props.appointment?.completed}</div>  
            <div>{"DATA COMPLETAMENTO: "+props.appointment?.completion_date}</div>  
            <div>{"NOTE: "+props.appointment?.appointment_notes}</div>            
        </div>
    )
}